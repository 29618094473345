import { CircularProgress } from '@mui/material';
import { Box, styled, Card } from '@mui/system';

const StyledLoading = styled('div')(() => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
  backgroundColor: 'rgba(249, 249, 249, 0.1)',

  '& img': {
    width: 'auto',
    height: '25px',
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -7,
    right: 0,
    top: 'calc(50% - 23px)',
    color: '#00CD98'
  },
}));

const Loading = () => {
  return (
    <StyledLoading>
      <Box position="relative" >
        <img src="/favicon.svg" alt="" />
        <CircularProgress className="circleProgress" />
      </Box>
    </StyledLoading>
  );
};

export default Loading;
