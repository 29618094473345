import '../styles/globals.css';
import { Provider } from 'react-redux';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import { SnackbarProvider } from './contexts/SnackbarContext'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptLocale from 'date-fns/locale/pt-BR';

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptLocale}>
      <Provider store={Store}>
        <SettingsProvider>
          <SnackbarProvider>
            <MatxTheme>
              <AuthProvider />
            </MatxTheme>
          </SnackbarProvider>
        </SettingsProvider>
      </Provider>
    </LocalizationProvider>
  );
};

export default App;
