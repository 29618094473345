import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
// import * as serviceWorker from './serviceWorker';

import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </StyledEngineProvider>

)

// ReactDOM.render(
//   ,
//   document.getElementById('root')
// );

// // for IE-11 support un-comment cssVars() and it's import in this file
// // and in MatxTheme file

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
