import React, { createContext, useEffect, useReducer, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { MatxLoading } from 'app/components'
import { api, reportsApi } from 'app/services/api/baseApi'
import { SignIn } from 'app/services/api/authApi'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import NotFound from 'app/views/sessions/NotFound'
import AuthGuard from 'app/auth/AuthGuard'
import { MatxLayout } from 'app/components'
import Home from 'app/views/home'
import { useRoutes, Navigate } from 'react-router-dom';
import { GetUserMenu } from 'app/services/api/userApi'
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    return true
    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (credentials) => {
    if (credentials) {
        localStorage.setItem('credentials', JSON.stringify(credentials))
        api.defaults.headers.common.Authorization = `Bearer ${credentials.accessToken}`
        reportsApi.defaults.headers.common.Authorization = `Bearer ${credentials.accessToken}`
    } else {
        localStorage.removeItem('credentials')
        delete api.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user, userRoutes } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                userRoutes
            }
        }
        case 'LOGIN': {
            const { user, userRoutes } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
                userRoutes
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [userRoutes, setUserRoutes] = useState([]);
    const [userMenu, setUserMenu] = useState([]);
    const [loadingRoutes, setLoadingRoutes] = useState(true);

    const login = async (email, password) => {
        const response = await SignIn({ email, password })
        const decodedToken = jwtDecode(response.data.data.accessToken)
        setSession(response.data.data)
        GetUserMenus()
        dispatch({
            type: 'LOGIN',
            payload: {
                decodedToken,
                user: {
                    id: decodedToken.userId,
                    name: decodedToken.name,
                    email: decodedToken.email,
                    institutionName: decodedToken.institutionName
                }
            },
        })
    }

    const register = async (email, username, password) => {
        const response = await api.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)
        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        setUserRoutes([]);
        setUserMenu([]);
        dispatch({ type: 'LOGOUT' })
    }

    const GetUserMenus = () => {
        GetUserMenu().then(
            (res) => {
                try {
                    const dt = res.data.data?.map(m => {
                        return (m.children.filter(m => m.renderable).map(mi => {
                            const Component = Loadable(lazy(() => import(`../views${mi.componentRoute}`)))
                            return (
                                {
                                    path: mi.path,
                                    element: <Component />
                                }

                            )
                        }))
                    })
                    const usrRoutes = [{
                        element: (
                            <AuthGuard>
                                <MatxLayout />
                            </AuthGuard>
                        ),
                        children: [...[].concat(...dt), ...materialRoutes],
                    }];
                    setUserRoutes(usrRoutes);
                    setUserMenu(res.data.data);
                    setLoadingRoutes(false);
                } catch {
                    setLoadingRoutes(false);
                }
            })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const credentials = window.localStorage.getItem('credentials')
                let credentialsJson = JSON.parse(credentials)
                if (credentialsJson && isValidToken(credentialsJson.accessToken)) {
                    setSession(credentialsJson)
                    const decodedToken = jwtDecode(credentialsJson.accessToken)
                    GetUserMenus();

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: {
                                id: decodedToken.userId,
                                name: decodedToken.name,
                                email: decodedToken.email,
                                institutionName: decodedToken.institutionName
                            }
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                    setLoadingRoutes(false);
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
                setLoadingRoutes(false);
            }
        })()
    }, [])

    const totalRoutes =
        [
            {
                element: (
                    <AuthGuard>
                        <MatxLayout />
                    </AuthGuard>
                ),
                children: [
                    { path: '/home', element: <Home /> },
                    { path: '*', element: <NotFound /> }
                ],
            },
            ...sessionRoutes,
            { path: '/', element: <Navigate to="home" /> },
            ...userRoutes,

        ];

    const routeElement = useRoutes(totalRoutes);

    if (!state.isInitialised || loadingRoutes) {
        return <MatxLoading />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                userMenu
            }}
        >
            {routeElement}
        </AuthContext.Provider>
    )
}

export default AuthContext
