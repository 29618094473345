import axios from 'axios';

const onRequestFailure = (error) => {
    const { response } = error
    const credentials = JSON.parse(window.localStorage.getItem('credentials'))
    if ((response?.status === 401) && !error?.config?.__isRetryRequest) {
        error.config.__isRetryRequest = true

        return api.post("/Authentication/RefreshToken", { accessToken: credentials?.accessToken, refreshToken: credentials?.refreshToken })
            .then((response) => {

                localStorage.setItem('credentials', JSON.stringify(response.data.data))
                api.defaults.headers.common.Authorization = `Bearer ${response.data.data.accessToken}`
                error.config.headers.Authorization = response.data.data.accessToken ? `Bearer ${response.data.data.accessToken}` : ''

                return api(error.config)
            })
            .catch(e => {
                console.error(e)
                return Promise.reject(error)
            })
    }
    else if ((response?.status === 403)) {
        return Promise.reject({ response: { data: { errors: ['Você não tem permissão para acessar esta interface.'] } } })
    }
    return Promise.reject(error)
}
export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.response.use(
    response => response,
    error => onRequestFailure(error),
)

export const reportsApi = axios.create({
    baseURL: process.env.REACT_APP_API_REPORTS_URL
});

reportsApi.interceptors.response.use(
    response => response,
    error => onRequestFailure(error),
)