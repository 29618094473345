import {
    TableSortLabel
} from "@mui/material";

const AscDesc = ({ label, column, setFilters, filters }) => {

    const handleTableSort = (columns) => {
        setFilters((prevState) => ({ ...prevState, orderBy: columns, direction: prevState.direction === "asc" ? "desc" : "asc" }))
    }

    return (
        <TableSortLabel
            active={filters.orderBy === column}
            onClick={() => handleTableSort(column)}
            direction={filters.direction || 'asc'} >
            {label || column}
        </TableSortLabel>
    )
}
export default AscDesc