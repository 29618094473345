import { themeShadows } from './themeColors';
import { themeColors } from './themeColors';
export const components = {
  html: {
    scrollBehavior: "smooth",
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f2f2f2',
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
  },

  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        color: '#45484C',
      },
      indicator: {
        backgroundColor: '#45484C',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: '#2B6D69',
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: '#45484C',
        },
      },
    },
  },

  MuiDateCalendar: {
    styleOverrides: {
      root: {
        color: '#45484C',
        borderColor: '#f9f9f9',
        backgroundColor: '#f9f9f9',
      }
    }
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        overflow: 'auto',
        scrollbarWidth: 'thin', // Firefox
        '&::-webkit-scrollbar': {
          width: '2px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '2px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#f2f2f2',
        },
      },
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '10px', // Ajuste conforme necessário
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        margin: '16px 16px 16px 16px', // Ajuste conforme necessário
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover fieldset': {
          borderColor: '#45484C', // Cor da borda ao passar o mouse
        },
        '&.Mui-focused fieldset': {
          borderColor: 'red', // Cor da borda quando focado (clicado)
        },
      },
      notchedOutline: {
        borderColor: '#45484C', // Cor da borda quando não selecionado
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        fill: '#45484C',  // Cor do ícone
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-thumb': {
          color: '#45484C', // Cor do botão do switch quando está desligado
        },
        '& .MuiSwitch-track': {
          backgroundColor: '#B0BEC5', // Cor da faixa do switch quando está desligado
        },
        '& .MuiSwitch-switchBase': {
          color: '#45484C', // Cor do círculo ao redor do botão quando está desligado
        },

        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: themeColors.marketCentralLight.palette.mkt.main, // Cor da faixa do switch quando está ligado
        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
          color: themeColors.marketCentralLight.palette.mkt.main, // Cor do botão do switch quando está ligado
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: themeColors.marketCentralLight.palette.mkt.main, // Cor do círculo ao redor do botão quando está ligado
        },

        '& .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb': {
          color: themeColors.marketCentralLight.palette.mkt.main, // Cor do botão do switch quando está focado
        },
        '& .MuiSwitch-switchBase.Mui-focusVisible + .MuiSwitch-track': {
          backgroundColor: themeColors.marketCentralLight.palette.mkt.main, // Cor da faixa do switch quando está focado
        },
      },
    },
  },


  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: themeColors.marketCentralLight.palette.mkt.main, // Cor do checkbox quando está marcado
        },
        '&.MuiCheckbox-indeterminate': {
          color: themeColors.marketCentralLight.palette.mkt.main, // Cor do checkbox quando está indeterminado
        },
        '&:not(.Mui-checked):not(.MuiCheckbox-indeterminate)': {
          color: '#45484C', // Cor do checkbox quando está desmarcado
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${themeColors.marketCentralLight.palette.mkt.main}`, // Contorno quando o checkbox está focado
        },
      },
    },
  },


  MuiSlider: {
    styleOverrides: {
      root: {
        color: '#45484C', // Cor do traço do Slider
        '& .MuiSlider-thumb': {
          backgroundColor: '#42464B', // Cor do botão do Slider
        },
        '& .MuiSlider-valueLabel': {
          color: '#fff', // Cor do texto do valor do Slider
        },
      },
    },
  },


  MuiTextField: {
    styleOverrides: {
      root: {

        '& .MuiInputLabel-root': {
          color: '#45484C', // Cor do rótulo
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#42464B', // Cor do rótulo quando focado
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#45484C', // Cor do contorno padrão
          },
          '&:hover fieldset': {
            borderColor: '#42464B', // Cor do contorno ao passar o mouse
          },
          '&.Mui-focused fieldset': {
            borderColor: '#42464B', // Cor do contorno quando focado
          },
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {

        '& .MuiFormLabel-root': {
          color: '#45484C', // Cor do rótulo

        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#42464B', // Cor do rótulo quando focado
        },
        '&:hover fieldset': {
          borderColor: '#42464B', // Cor do contorno ao passar o mouse
        },
        '&.Mui-focused fieldset': {
          borderColor: '#42464B', // Cor do contorno quando focado
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        tableLayout: 'fixed',
        textTransform: 'none'
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: '13px',
        padding: '12px 0px',
      },
      root: {
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        padding: '0px 8px',
      },
    },
  },
  MUIDataTableSelectCell: {
    styleOverrides: {
      root: {
        paddingLeft: 12,
      },
    },
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: {
        paddingLeft: 16,
      },
    },
  },
  MUIDataTableBodyCell: {
    styleOverrides: {
      root: {
        paddingLeft: 8,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        textTransform: 'none',
        fontWeight: '400',
      },
      contained: {
        boxShadow: themeShadows[8],
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
      '#root': {
        height: '100%',
      },
      '#nprogress .bar': {
        zIndex: '2000 !important',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: themeShadows[12],
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '11px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '0.875rem',
      },
    },
  },
  MuiExpansionPanel: {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%) !important',
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        '&.Mui-completed': {
          color: themeColors.marketCentralLight.palette.mkt.main,
        },
        '&.Mui-active': {
          color: themeColors.marketCentralLight.palette.mktDark.main,
        },
        '&.Mui-disabled': {
          color: themeColors.marketCentralLight.palette.mktDark.main,
        },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        '&.Mui-completed': {
          color: themeColors.marketCentralLight.palette.mkt.contrastText,
        },
        '&.Mui-active': {
          color: themeColors.marketCentralLight.palette.mktDark.contrastText,
        },
        '&.Mui-disabled': {
          color: themeColors.marketCentralLight.palette.mktDark.contrastText,
        },
      },
    },
  }

};
