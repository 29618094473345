export const navigations = [

  // { label: 'oficiais', type: 'label' },
  // { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },

  // {
  //   name: 'Administrativo',
  //   icon: 'admin_panel_settings',
  //   children: [
  //     { name: 'Planos', icon: 'payments', iconText: 'Planos', path: '/business-plan' },
  //     { name: 'Empresas', icon: 'store', iconText: 'Empresas', path: '/institution' },
  //     { name: 'Menu', icon: 'widgets', iconText: 'Menu', path: '/menu' },
  //     { name: 'Grupos de usuário', icon: 'group', iconText: 'Grupos', path: '/user-group' },
  //   ],
  // },


  // {
  //   name: 'Cadastros',
  //   icon: 'add_box',
  //   children: [
  //     { name: 'Produtos', icon: 'local_mall', iconText: 'Planos', path: '/business-plan' },
  //     { name: 'Funcionarios', icon: 'people', iconText: 'Empresas', path: '/institution' },
  //     { name: 'Fornecedores', icon: 'local_shipping', iconText: 'Menu', path: '/menu' },
  //     {
  //       name: 'Relatorios', icon: 'insert_chart',
  //       children: [
  //         { name: 'Produtos', icon: 'local_mall', iconText: 'Planos', path: '/business-plan' },
  //         { name: 'Funcionarios', icon: 'people', iconText: 'Empresas', path: '/institution' },
  //         { name: 'Fornecedores', icon: 'local_shipping', iconText: 'Menu', path: '/menu' },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: 'Compras',
  //   icon: 'shopping_cart',
  //   children: [
  //     { name: 'Sugestão de compras', icon: 'shopping_basket', iconText: 'Planos', path: '/business-plan' },
  //     { name: 'Pedido de compras', icon: 'assignment', iconText: 'Empresas', path: '/institution' },
  //     { name: 'Entrada de NF-e', icon: 'receipt', iconText: 'Planos', path: '/business-plan' },
  //     { name: 'Relatorios', icon: 'insert_chart', iconText: 'Grupos', path: '/user-group' },
  //   ],
  // },



  // {
  //   name: 'Components',
  //   icon: 'favorite',
  //   visible: true,
  //   badge: { value: '30+', color: 'secondary' },
  //   children: [
  //     { visible: true, name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
  //     { visible: true, name: 'Buttons', path: '/material/buttons', iconText: 'B' },
  //     { visible: true, name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
  //     { visible: true, name: 'Dialog', path: '/material/dialog', iconText: 'D' },
  //     { visible: true, name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
  //     { visible: true, name: 'Form', path: '/material/form', iconText: 'F' },
  //     { visible: true, name: 'Icons', path: '/material/icons', iconText: 'I' },
  //     { visible: true, name: 'Menu', path: '/material/menu', iconText: 'M' },
  //     { visible: true, name: 'Progress', path: '/material/progress', iconText: 'P' },
  //     { visible: true, name: 'Radio', path: '/material/radio', iconText: 'R' },
  //     { visible: true, name: 'Switch', path: '/material/switch', iconText: 'S' },
  //     { visible: true, name: 'Slider', path: '/material/slider', iconText: 'S' },
  //     { visible: true, name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
  //     { visible: true, name: 'Table', path: '/material/table', iconText: 'T' },
  //   ],
  // }
];
