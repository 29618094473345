import React, { createContext, useContext, useState } from 'react';
import {
  Alert, Snackbar,
  TableContainer
} from "@mui/material";
import AscDesc from "app/components/AscDesc";
import Loading from '../components/MatxLoading';
const { v4: uuidv4 } = require('uuid');

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [alertSeverity, setalertSeverity] = useState('');
  const [loading, setloading] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState([]);


  const openSnackbar = (alertSeverity, message) => {
    setalertSeverity(alertSeverity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const FeedbackRequest = (request, successLabel, thenSuccess) => {
    const requestId = uuidv4();
    setLoadingRequests(prevState => [...prevState, requestId]);

    setloading(true)

    request.then((res) => {

      if (thenSuccess) {
        thenSuccess(res);
      }

      if (successLabel) {
        openSnackbar('success', successLabel);
      }
      setloading(false)
    }).catch((error) => {
      if (error?.response?.data) {
        if (Array.isArray(error?.response?.data?.errors)) {
          openSnackbar('error', error?.response?.data?.errors?.join(' - '));
        } else {
          openSnackbar('error', "Falha na requisição, contacte o suporte!");
        }

      } else {
        openSnackbar('error', "Falha na requisição, contacte o suporte!");
      }
    }).finally(() => {
      setloading(false)
      setLoadingRequests(prevState => prevState.filter(guid => guid !== requestId));

    })
  }

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar, FeedbackRequest }}>
      {(loading || loadingRequests.length > 0) && <Loading />}
      {children}
      <Snackbar onClose={closeSnackbar} open={snackbarOpen} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={alertSeverity || `success`} sx={{ width: "100%" }} variant="filled" >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
